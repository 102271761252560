import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { CIContext } from "../context"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
// import { sleep } from "../../../utils/common"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
// import UserBarChart from "../charts/users-chart-bar"
import { FaRedoAlt, FaRegFileExcel } from "react-icons/fa"
import moment from "moment"
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa"
import Pagination from "@material-ui/lab/Pagination"
import { Select, MenuItem, Tooltip } from "@material-ui/core"

function PipelinesTabular(props) {
  let contextData = useContext(CIContext)
  const { getAccessTokenSilently } = useAuth0()
  var [show, setShow] = useState(false)
  var [dbRecords, setdbRecords] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchCounter, setSearchCounter] = useState(0)

  function onPageChange(event, page) {
    setCurrentPage(page)
    setSearchCounter(n => n + 1)
  }

  function onPageSizeChange(event) {
    const size = parseInt(event.target.value, 10)
    setPageSize(size)
    setCurrentPage(1)
    setSearchCounter(n => n + 1)
  }

  const LoadTotalUsersReport = async () => {
    // var orgCode = contextData.selectedParentOrganization.name
    var porgid = contextData.selectedParentOrganization.id
    var orgid = contextData.selectedOrganization.id
    var startDate = moment(contextData.startDate).format("YYYY-MM-DD")
    var endDate = moment(contextData.endDate).format("YYYY-MM-DD")
    var postdata = { porgid: porgid, orgID: orgid, startDate, startDate, endDate: endDate }
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    var qs = `startDate=${startDate}&endDate=${endDate}&orgID=${orgid}&page=${currentPage}&limit=${pageSize}`

    // await sleep(500)
    console.log(postdata, qs)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/users/report`, accessToken)
        fetch_get(`ci/admin/pipelines?${qs}`, accessToken)
          .then(response => {
            if (response.status.data.length > 0) contextData.setHasRecords(true)
            setdbRecords(response.status.data)
            const totalRecords = response.status.pagination.totalRecords || 0

            setPageCount(Math.ceil(totalRecords / pageSize))
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting project links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  useEffect(() => {
    // console.log(contextData)
    if (contextData.submitCounter === 0) return
    if (_.isNil(contextData.selectedParentOrganization)) {
      // console.log("from load users", contextData.selectedParentOrganization)
      setdbRecords([])
      setShow(false)
      return
    }
    // console.log(contextData.selectedParentOrganization)
    setShow(true)
    LoadTotalUsersReport()
  }, [contextData.submitCounter])

  useEffect(() => {
    // console.log(contextData)
    if (searchCounter === 0) return
    setdbRecords([])

    setShow(true)
    LoadTotalUsersReport()
  }, [searchCounter])

  return (
    <React.Fragment>
      <>
        {show && (
          <>
            <div id="accounts-total-users-data">
              <div className="float-right">
                <div className="form-inline">
                  <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={onPageChange}
                    variant="outlined"
                    shape="rounded"
                  />

                  <Select
                    labelId="pageSize"
                    id="page-size"
                    value={pageSize}
                    onChange={onPageSizeChange}
                    className="pageSizeSelect"
                  >
                    <MenuItem value={10}>10 / page</MenuItem>
                    <MenuItem value={50}>50 / page</MenuItem>
                    <MenuItem value={100}>100 / page</MenuItem>
                  </Select>
                </div>
              </div>

              <br />
              <br />
              <div>
                <div className="float-left">
                  <h5>List of Pipelines</h5>
                </div>

                {!contextData.pdfExportStarted && (
                  <>
                    <div className="float-right">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-sm"
                        onClick={() => LoadTotalUsersReport()}
                      >
                        {/* <strong>Refresh</strong> */}
                        <FaRedoAlt />
                      </button>
                      {/* &nbsp;
                    <button
                      type="button"
                      className="btn btn-warning btn-sm"
                      onClick={() =>
                        window["generatePDF"]("accounts-total-users-data", "AccountBilling-users")
                      }
                    >
                      <strong>pdf</strong>
                    </button> */}
                      {/* &nbsp;
                      <button
                        type="button"
                        className="btn btn-outline-success btn-sm"
                        onClick={() =>
                          window["exportTableToExcel"](
                            "accounts-excel-users-data",
                            "",
                            "AccountBilling-users-excel"
                          )
                        }
                      >
                        <FaRegFileExcel />
                      </button> */}
                    </div>
                  </>
                )}
              </div>

              <div>
                {loadStatus.loading && !loadStatus.error && (
                  <>
                    <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading...
                  </>
                )}
                {!loadStatus.loading && !loadStatus.error && (
                  <>
                    {dbRecords.length === 0 && <div>Sorry ! No Info found</div>}
                    {dbRecords.length > 0 && (
                      <>
                        <div id="accounts-excel-users-data">
                          <span style={{ display: "none" }}>Pipelines Report</span>
                          <table className="table table-sm">
                            <thead>
                              <tr>
                                <th>Pipeline ID</th>
                                <th>Status</th>
                                <th>Project Name</th>
                                <th>Repo Name</th>
                                <th>Cost / Adjusted Cost</th>
                                {/* <th>Adjusted Cost</th> */}
                                <th>Triggered by</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dbRecords.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.pipeline_id}</td>
                                    <td>
                                      {item.pipeline_status === "failed" && (
                                        <>
                                          <FaThumbsDown style={{ fill: "red" }} />
                                          &nbsp;
                                        </>
                                      )}
                                      {item.pipeline_status === "success" && (
                                        <>
                                          <FaThumbsUp style={{ fill: "green" }} />
                                          &nbsp;
                                        </>
                                      )}
                                      {_.startCase(item.pipeline_status)}
                                    </td>
                                    <td>
                                      {item.project_name !== null && (
                                        <>
                                          <a
                                            target="_blank"
                                            href={`/organization/${item.org_key}/project/${item.project_slug}/metrics`}
                                          >
                                            {item.project_name}
                                          </a>
                                        </>
                                      )}
                                      {item.project_name === null && (
                                        <>
                                          <a
                                            target="_blank"
                                            href={`/metrics/users/${item.owner_username}/`}
                                          >
                                            {item.owner_user_display_name}
                                          </a>
                                          &nbsp;(<span className="font-13">Personal</span>)
                                        </>
                                      )}
                                    </td>

                                    <td>{item.repo_name}</td>

                                    <td>
                                      $<strong>{new Number(item.cost).toFixed(2)}</strong>
                                      <br />$<strong>{new Number(item.adjusted_cost).toFixed(2)}</strong>
                                    </td>
                                    {/* <td>
                                      $<strong>{item.adjusted_cost}</strong>
                                    </td> */}
                                    <td>
                                      {item.trigger_username !== item.trigger_user_display_name && (
                                        <>
                                          <a
                                            target="_blank"
                                            href={`/metrics/users/${item.trigger_username}/`}
                                          >
                                            {item.trigger_user_display_name}
                                          </a>
                                        </>
                                      )}
                                      {item.trigger_username === item.trigger_user_display_name && (
                                        <>{item.trigger_user_display_name}</>
                                      )}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <div className="float-right">
                          <div className="form-inline">
                            <Pagination
                              count={pageCount}
                              page={currentPage}
                              onChange={onPageChange}
                              variant="outlined"
                              shape="rounded"
                            />

                            <Select
                              labelId="pageSize"
                              id="page-size"
                              value={pageSize}
                              onChange={onPageSizeChange}
                              className="pageSizeSelect"
                            >
                              <MenuItem value={10}>10 / page</MenuItem>
                              <MenuItem value={50}>50 / page</MenuItem>
                              <MenuItem value={100}>100 / page</MenuItem>
                            </Select>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {!loadStatus.loading && loadStatus.error && (
                  <>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="alert alert-danger py-1" role="alert">
                      {loadStatus.errorMessage}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </React.Fragment>
  )
}

export default PipelinesTabular
