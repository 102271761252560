import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { CIContext } from "../context"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
// import { sleep } from "../../../utils/common"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
// import UserBarChart from "../charts/users-chart-bar-apex"
import PipelinesTabular from "../data/pipelines-tabular"

function PipeLines(props) {
  let contextData = useContext(CIContext)
  const { getAccessTokenSilently } = useAuth0()
  var [show, setShow] = useState(false)

  useEffect(() => {
    // console.log(contextData)
    if (contextData.submitCounter === 0) return
    if (_.isNil(contextData.selectedParentOrganization)) {
      // console.log("from load users", contextData.selectedParentOrganization)

      setShow(false)
      return
    }
    // console.log(contextData.selectedParentOrganization)
    setShow(true)
    // LoadTotalUsersReport()
  }, [contextData.submitCounter])

  return (
    <React.Fragment>
      <>
        {show && (
          <>
            <div id="accounts-total-users-data">
              {/* <div className="float-left">
                <h3>Pipelines</h3>
              </div> */}

              <div>
                {/* <br />
                <br /> */}

                <div className="container">
                  {/* <div className="row"> */}
                  <PipelinesTabular />
                  {/* </div> */}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </React.Fragment>
  )
}

export default PipeLines
