import React, { createContext, useEffect, useState } from "react"
import moment from "moment"
export const CIContext = createContext()

export const CIContextProvider = ({ children }) => {
  var [selectedParentOrganization, setSelectedParentOrganization] = React.useState(null)
  var [selectedOrganization, setSelectedOrganization] = React.useState(null)
  var [submitCounter, setSubmitCounter] = React.useState(0)
  const defalutStartDate = moment().subtract(1, "months").format("YYYY-MM-DD")
  var [startDate, setStartDate] = React.useState(new Date(defalutStartDate))
  var [endDate, setEndDate] = React.useState(new Date(moment().format("YYYY-MM-DD")))
  var [hasRecords, setHasRecords] = React.useState(false)
  var [pdfExportStarted, setPdfExportStarted] = React.useState(false)

  console.log(startDate, endDate, moment(new Date()).format("YYYY-MM-DD"))

  return (
    <CIContext.Provider
      value={{
        selectedParentOrganization,
        setSelectedParentOrganization,
        selectedOrganization,
        setSelectedOrganization,
        submitCounter,
        setSubmitCounter,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        hasRecords,
        setHasRecords,
        pdfExportStarted,
        setPdfExportStarted,
      }}
    >
      {children}
    </CIContext.Provider>
  )
}
