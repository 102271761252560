import React from "react"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/header/header"
import { useUserOrgs } from "../../../hooks/use-user"
import CounterNoticeCreate from "../../../components/dmca/counternotice/create"
import "../../../styles/metrics.scss"
import { Loading } from "../../../components/loading"
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs"
import CIContainer from "../../../components/admin/ci/container"

const CreatePage = () => {
  const {
    loading,
    currentOrg: {
      index,
      role: { isProjectAdmin, isUserAdmin, isSystemAdmin, isSuperAdmin },
    },
  } = useUserOrgs()

  if (loading) {
    return <Loading />
  }
  return (
    <Layout pageTitle="Metrics">
      {isSuperAdmin && (
        <>
          <Breadcrumbs breadcrumbs={[{ label: "Account Usage" }]} />
          <Header contentPage={true} className="contentPage">
            <h1 className="display-4 font-weight-bold">CI Usage</h1>
          </Header>
          <Content>
            <CIContainer />
          </Content>
        </>
      )}
      {!isSuperAdmin && (
        <Content>
          <div className="alert alert-danger" role="alert">
            <strong>Access Denied</strong>
          </div>
        </Content>
      )}
    </Layout>
  )
}

export default CreatePage
